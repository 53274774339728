import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StayDetailJustificationService, Tab} from './stay-detail-justification.service';
import {Subscription} from 'rxjs';
import {StateService} from '@uirouter/core';
import {DataSetElement} from '../../shared/data-set-element.model';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {AppLogService} from '../../../../core/app-log/app-log.service';
import {StayDetailService} from '../stay-detail.service';
import {SearchEngineAdvancedService} from '../../../search-engine/advanced/search-engine-advanced.service';
import {QueryTypes} from '../../../search-engine/advanced/query-builder/enums/types';

@Component({
    selector: 'ct-stay-detail-justification',
    templateUrl: './stay-detail-justification.component.html',
    styleUrls: ['./stay-detail-justification.component.scss']
})
export class StayDetailJustificationComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    @Input() dataSetElement: DataSetElement;

    canDisplaySearchInput = true;
    isLoading: boolean;
    canDisplayStayRisk: boolean;
    query: string;
    private currentFilterseach: any;

    constructor(public $state: StateService,
                public stayDetailJustificationService: StayDetailJustificationService,
                private _configurationService: ConfigurationService,
                private _stayDetailService: StayDetailService,
                private _broadcastService: BroadcastService,
                private _logService: AppLogService,
                private _searchEngineAdvancedService: SearchEngineAdvancedService) {
    }

    ngOnInit() {
        this.canDisplayStayRisk = this._configurationService.getConfigurationContent('front', 'health.canDisplayStayRisk');
        this.currentFilterseach = JSON.parse(sessionStorage.getItem('currentFilterSearch')) || JSON.parse(sessionStorage.getItem('lastFilterSearchViewed')) || null;
        if (typeof this.currentFilterseach?.params === 'string') {
            this.currentFilterseach.params = JSON.parse(this.currentFilterseach.params);
        }
        if (this.$state &&
            this.$state.params) {
            this.canDisplaySearchInput = (!this.$state.params.fromFilterSearchList || this.$state.params.fromFilterSearchList === 'false') && (!this.$state.params.fromQualityControl || this.$state.params.fromQualityControl === 'false')
                && (!this.$state.params.forFilterSearch || this.$state.params.forFilterSearch === 'false') && !this.currentFilterseach;
        }
        this._initJustification();
        this._subscribeToBroadcast();
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetailCodificationNearAutomation::showDiagnosticJustification':
                    case 'dataSetElementStayJustification::addTab':
                        this.isLoading = true;
                        const diagnosticId = res.data.diagnostic.id;
                        const diagnosticSlug = res.data.diagnostic.slug;
                        const withChildren = res.data.diagnostic.id === res.data.diagnostic.parentId;
                        const stay = res.data?.stay;
                        const isAct = res.data?.isAct;

                        this.stayDetailJustificationService
                            .addTabByDiagnosticOrActId(diagnosticId, diagnosticSlug, withChildren, stay, isAct)
                            .then(tab => {
                                this.isLoading = false;
                                if (tab.highlightList) {
                                    this._broadcastService.send('stayDetailDocument::highlight', {tab: tab});
                                    this._broadcastService.send('stayDetailJustification::search');
                                }
                            })
                            .catch(() => this.isLoading = false);
                        break;
                    case 'dataSetElementStayJustification::switchTab':
                        const newTab = this.stayDetailJustificationService
                            .getTabById(res.data.id);
                        if (newTab &&
                            newTab.highlightList) {
                            this._broadcastService.send('stayDetailDocument::highlight', {tab: newTab});
                            this._broadcastService.send('stayDetailJustification::search', {tab: newTab});
                        }
                        break;
                    case 'stayDetailFilterSearch::reload':
                        this.canDisplaySearchInput = res.data ? res.data.isSelected : false;
                        this.currentFilterseach = res.data.filtersSearch;
                         this._initJustification(res.data ? res.data.reloadOnlyFirstTab : false);
                         this._broadcastService.send('stayDetailDocument::highlight', {tab: {highlightList: [], qDocumentTypeId: '-1', healthDocumentId: -1}});
                        break;
                    case 'stayDetailImpreciseDiagnostic::deleteDiagnostic':
                        if (res.data) {
                            this.stayDetailJustificationService.deleteTabByName(res.data.name, true);
                            this._broadcastService.send('stayDetailDocument::highlight', {tab: {highlightList: [], qDocumentTypeId: '-1', healthDocumentId: -1}});
                        }
                        break;
                    default:
                }
            });
        this._subscriptions.push(sub);
    }

    /**
     * To init justification service
     * @param reloadOnlyFirstTab if true then we don't clear all data but only "Recherche tab"
     * @private
     */
    private _initJustification(reloadOnlyFirstTab: boolean = false) {
        this.isLoading = true;

        const data: any = {
            stayId: this.dataSetElement.dataSetContent.stayId,
            rumId: this.dataSetElement.dataSetContent.rumId,
            query: this.currentFilterseach?.params?.query,
        };

        if (data.query?.args?.criteria[this._searchEngineAdvancedService.getQueryConditionIndex(data.query, 'bool')]?.args?.criteria[this.stayDetailJustificationService.getQueryDiagnosisMissingIndex(data.query)]?.args?.revaluation) {
            data.query.args.criteria[this._searchEngineAdvancedService.getQueryConditionIndex(data.query, 'bool')].args.criteria[this.stayDetailJustificationService.getQueryDiagnosisMissingIndex(data.query)].args.revaluation = false;
        }

        this.query = '';

        this.stayDetailJustificationService
            .init(data, reloadOnlyFirstTab)
            .then(tab => {
                this.query = tab.query || '';
                this.isLoading = false;
                this._broadcastService.send('stayDetailJustification::initDone', {reloadOnlyFirstTab});

                if ((
                        this._searchEngineAdvancedService.filterSearchQueryHasCondition('documentContent', data.query?.args?.criteria) ||
                        this._searchEngineAdvancedService.filterSearchQueryHasCondition('structuredData', data.query?.args?.criteria)
                    )
                    && tab.highlightList
                ) {
                    this._broadcastService.send('stayDetailDocument::highlight', {tab: tab});
                }
            })
            .catch((e) => {
                this.isLoading = false;
                console.error(e);
            });
    }

    isActiveTabFirstOne() {
        return this.stayDetailJustificationService.activeTabId === -1;
    }

    canDisplayDivider(tab: Tab) {
        return this.canDisplaySearchInput &&
            this.isActiveTabFirstOne() &&
            tab.highlight &&
            tab.highlight.length > 0;
    }

    getChipColor(creationDate: string) {
        return this._stayDetailService
            .getChipColor(this.dataSetElement, creationDate);
    }

    resetSearch() {
        this._broadcastService.send('stayDetailFilterSearch::reload', {isSelected: true, reloadOnlyFirstTab: true});
    }

    search(tab: Tab) {
        this.isLoading = true;
        // save query to display it the same way without transformation to filterSearchQuery
        const tmpQuery = this.query;
        tab.query = this.stayDetailJustificationService.getDocumentContentQuery(
            this.dataSetElement.dataSetContent.stayId,
            this.query
        );
        this.stayDetailJustificationService
            .reloadTab(tab)
            .then(reloadedTab => {
                this.query = tmpQuery;
                this.isLoading = false;
                this._broadcastService.send('stayDetailJustification::search');
                this._logService.logInfo('Search in documents');
                if (reloadedTab.highlightList) {
                    this._broadcastService.send('stayDetailDocument::highlight', {tab: reloadedTab});
                }
            })
            .catch(() => this.isLoading = false);
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
