<div class="stay-detail-container">
    <ng-container *ngIf="isActive">
        <ct-header
            *ngIf="!isDialog"
            [largeMargin]="true"
            [withoutMargin]="notFound"
        >
            <ct-header-content>
                <div>
                    <ct-stay-detail-header
                        [dataSetElement]="dataSetElement"
                        [isNearAutomationQualityControl]="isNearAutomationQualityControl"
                    ></ct-stay-detail-header>
                </div>
            </ct-header-content>
        </ct-header>

        <ng-container *ngIf="!notFound">
            <div
                *ngIf="dataSetElement"
                [ngStyle]="{'height': (isDialog ? '100%' : '')}"
                class="two-sides-container"
            >
                <!-- Left side -->
                <div
                    [@displayProperty]="leftSideDisplayState"
                    class="left-side"
                >
                    <div class="row full-height position-relative">
                        <ct-period-select
                            [activeTabId]="activeTabId"
                            [canDisplayRUM]="canDisplayRUM"
                            [dataSetElement]="dataSetElement"
                            [dataSetElementParent]="dataSetElementParent"
                            [isTypeExternal]="isTypeExternal"
                            [isTypeRehabilitation]="isTypeRehabilitation"
                            [SSRWeeks]="SSRWeeks"
                            [selectedSSRWeek]="selectedSSRWeek"
                            class="border-bottom shadow-sm"
                        ></ct-period-select>

                        <div class="col padding-16">
                            <!--Stay info-->
                            <ct-expansion-panel
                                [title]="stayDetailInfoHeaderTitle"
                                [titleCypressId]="'stay-id'"
                                [panelKey]="'info'"
                            >
                                <ct-expansion-panel-title-actions>
                                    <ct-stay-detail-info-header
                                        [dataSetElement]="dataSetElement"></ct-stay-detail-info-header>
                                </ct-expansion-panel-title-actions>

                                <ct-expansion-panel-body>
                                    <ct-stay-detail-info
                                        [dataSetElement]="dataSetElement"
                                        [dataSetElementParent]="dataSetElementParent"
                                    ></ct-stay-detail-info>
                                </ct-expansion-panel-body>
                            </ct-expansion-panel>

                            <!--Stay codification (diagnostics, acts)-->
                            <ct-expansion-panel
                                *ngIf="!isTypeExternal"
                                [title]="'DATA_SET.CODIFICATION_ELEMENT.CODIFICATION' | translate"
                                [panelKey]="'codification'"
                            >
                                <ct-expansion-panel-title-actions>
                                    <ct-stay-detail-codification-header
                                        [dataSetElement]="dataSetElement"
                                    ></ct-stay-detail-codification-header>
                                </ct-expansion-panel-title-actions>

                                <ct-expansion-panel-body>
                                    <ct-stay-detail-codification
                                        [dataSetElement]="dataSetElement"
                                        [dataSetElementParent]="dataSetElementParent"
                                        [dataSetCodification]="dataSetCodification"
                                        [diagnostics]="dataSetElement.dataSetElementDiagnostic"
                                        [predictiveDiagnostics]="dataSetElement.dataSetElementDiagnosticPredictive"
                                        [isNearAutomationQualityControl]="isNearAutomationQualityControl"
                                        [isTypeRehabilitation]="isTypeRehabilitation"
                                        [isTypeExternal]="isTypeExternal"
                                        [selectedSSRWeek]="selectedSSRWeek"
                                        [SSRWeeks]="SSRWeeks"
                                        [canDisplayRUM]="canDisplayRUM"
                                        [limitCodificationUpdateToDA]="activeTabId === SmrTabs.OnGoing"
                                        [codificationLabels]="codificationLabels"
                                    ></ct-stay-detail-codification>
                                </ct-expansion-panel-body>
                            </ct-expansion-panel>

                            <!-- Only display dependencies if stay is SMR -->
                            <ct-expansion-panel
                                *ngIf="isTypeRehabilitation"
                                [title]="'DATA_SET.CODIFICATION_ELEMENT.SRR.DEPENDENCIES' | translate"
                                [isExpanded]="false"
                            >
                                <ct-expansion-panel-body>
                                    <ct-dependencies [dependencies]="dependencies"></ct-dependencies>
                                </ct-expansion-panel-body>
                            </ct-expansion-panel>

                            <ng-container
                                *ngIf="isNearAutomationQualityControl"
                                [ngTemplateOutlet]="stayAIPredictionsTemplate"
                            ></ng-container>

                            <div #filterSearchExpansionPanel>
                                <!--Stay filters search-->
                                <ct-expansion-panel
                                    [title]="'DATA_SET.CODIFICATION_ELEMENT.ASSOCIATED_FILTERS_SEARCH' | translate"
                                    [panelKey]="'filter-search'"
                                    [isExpanded]="!!dataSetElement?.filtersSearch?.length">
                                    <ct-expansion-panel-title-actions>
                                        <ng-container>
                                            <ng-container *ngIf="!isLoading">
                                                <button mat-icon-button
                                                        class="menu-button"
                                                        *ngIf="canCodify"
                                                        (click)="openMenu($event)"
                                                        [matMenuTriggerFor]="codifyMenu">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>

                                                <mat-menu #codifyMenu="matMenu"
                                                          [class]="'codify-stay-menu'"
                                                >
                                                    <ng-template matMenuContent>
                                                        <button mat-menu-item
                                                                (click)="linkCodifyDataSetElement()"
                                                        >
                                                            <span>{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.LINK_CODIFY' | translate}}</span>
                                                        </button>
                                                    </ng-template>
                                                </mat-menu>
                                            </ng-container>
                                            <div class="spinner margin-left-5"
                                                 *ngIf="isLoading">
                                                <mat-progress-spinner
                                                    [diameter]="15"
                                                    mode="indeterminate">
                                                </mat-progress-spinner>
                                            </div>
                                        </ng-container>
                                    </ct-expansion-panel-title-actions>
                                    <ct-expansion-panel-body>
                                        <ct-stay-detail-filter-search
                                            [dataSetElement]="dataSetElement"
                                            [dataSetElementParent]="dataSetElementParent"
                                            [diagnostics]="dataSetElement.dataSetElementDiagnostic"
                                            [predictiveDiagnostics]="getDataSetElementDiagnosticPredictive('rule')"
                                            [codificationLabels]="codificationLabels"
                                            [isTypeRehabilitation]="isTypeRehabilitation"></ct-stay-detail-filter-search>
                                    </ct-expansion-panel-body>
                                </ct-expansion-panel>
                            </div>

                            <ng-container *ngIf="!isNearAutomationQualityControl"
                                          [ngTemplateOutlet]="stayAIPredictionsTemplate"></ng-container>

                            <ng-template #stayAIPredictionsTemplate>
                                <!--Stay AI predictions-->
                                <ct-expansion-panel *ngIf="canDisplayStayPredictions"
                                                    [title]="'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.PROPOSALS' | translate"
                                                    [panelKey]="'diagnostic-prediction'"
                                                    [isExpanded]="!!dataSetElement?.dataSetElementDiagnostic?.length">
                                    <ct-expansion-panel-title-actions>
                                        <ct-stay-detail-diagnostic-prediction-header
                                            [dataSetElement]="dataSetElement"></ct-stay-detail-diagnostic-prediction-header>
                                    </ct-expansion-panel-title-actions>

                                    <ct-expansion-panel-body>
                                        <ct-stay-detail-diagnostic-prediction
                                            [dataSetElement]="dataSetElement"
                                            [dataSetElementParent]="dataSetElementParent"
                                            [dataSetCodification]="dataSetCodification"
                                            [codificationLabels]="codificationLabels"
                                            [diagnostics]="dataSetElement.dataSetElementDiagnostic"
                                            [predictiveDiagnostics]="getDataSetElementDiagnosticPredictive('ml')"
                                            [predictiveActs]="getDataSetElementActPredictive('ml')"
                                            [isNearAutomationQualityControl]="isNearAutomationQualityControl"
                                            [isTypeRehabilitation]="isTypeRehabilitation"
                                            [canDisplayTooltip]="true"
                                            [displayOnlySimplifiedScore]="false"></ct-stay-detail-diagnostic-prediction>
                                    </ct-expansion-panel-body>
                                </ct-expansion-panel>
                            </ng-template>

                            <!--Stay medical history-->
                            <ct-expansion-panel

                                [title]="'HEALTH.MEDICAL_HISTORY' | translate"
                                [panelKey]="'chronic-diagnostic'"
                                [isExpanded]="!!diagnosticHistory?.length"
                            >
                                <ct-expansion-panel-body>
                                    <ct-stay-detail-chronic-diagnostic
                                        [dataSetElement]="dataSetElement"
                                        [diagnostics]="dataSetElement.dataSetElementDiagnostic"
                                        [codificationLabels]="codificationLabels"
                                        [isTypeRehabilitation]="isTypeRehabilitation"
                                    ></ct-stay-detail-chronic-diagnostic>
                                </ct-expansion-panel-body>
                            </ct-expansion-panel>

                            <div #impreciseDiagnosticExpansionPanel
                                 *ngIf="canDisplayImpreciseDiagnostic && !isTypeExternal">
                                <!--Stay imprecise diagnostic-->
                                <ct-expansion-panel
                                    [title]="'DIAGNOSTIC.IMPRECISE_DIAGNOSTICS' | translate"
                                    [panelKey]="'imprecise-diagnostic'"
                                    [isExpanded]="!!impreciseDiagnostics?.length"
                                >
                                    <ct-expansion-panel-body>
                                        <ct-stay-detail-imprecise-diagnostic
                                            [dataSetElement]="dataSetElement"
                                            [diagnostics]="dataSetElement.dataSetElementDiagnostic"
                                            [isTypeRehabilitation]="isTypeRehabilitation"
                                            [codificationLabels]="codificationLabels"
                                        >
                                        </ct-stay-detail-imprecise-diagnostic>
                                    </ct-expansion-panel-body>
                                </ct-expansion-panel>
                            </div>

                            <!--Stay document list-->
                            <ct-expansion-panel
                                [title]="'DATA_SET.CODIFICATION_ELEMENT.DOCUMENT.LIST' | translate"
                                [panelKey]="'document-list'"
                                [isExpanded]="!!docNb"
                            >
                                <ct-expansion-panel-body>
                                    <ct-stay-detail-document-list></ct-stay-detail-document-list>
                                </ct-expansion-panel-body>
                            </ct-expansion-panel>
                        </div>
                    </div>
                </div>

                <mat-divider [vertical]="true"></mat-divider>

                <!-- Right side -->
                <div class="right-side">
                    <div class="scroll-container"
                         (scroll)="onRightSideScroll($event)">
                        <ct-stay-detail-collapse-button
                            *ngIf="hasScrolled"
                            [leftSideDisplayState]="leftSideDisplayState"
                            (buttonClicked)="toggleSideCollapse('leftSideDisplayState')"
                        ></ct-stay-detail-collapse-button>
                        <div class="row">
                            <div class="col padding-16">
                                <ct-stay-detail-document
                                    [dataSetElement]="dataSetElement"
                                    [leftSideDisplayState]="leftSideDisplayState"
                                    [codificationHistoryDisplayState]="codificationHistoryDisplayState"
                                    [commentDisplayState]="commentDisplayState"
                                    (toggleLeftSideCollapse)="toggleSideCollapse('leftSideDisplayState')"
                                    (toggleCodificationHistoryCollapse)="toggleSideCollapse('codificationHistoryDisplayState')"
                                    (toggleCommentCollapse)="toggleSideCollapse('commentDisplayState')"
                                ></ct-stay-detail-document>
                            </div>
                        </div>
                    </div>

                    <div class="fab-buttons">
                        <button mat-fab
                                color="primary"
                                class="scroll-button"
                                [ngClass]="{'fade-in': hasScrolled && showFabButtons, 'fade-out': !hasScrolled || !showFabButtons}"
                                (mouseover)="changeFabButtonOver(true)"
                                (mouseout)="changeFabButtonOver(false)"
                                (click)="scrollToTop()">
                            <mat-icon>expand_less</mat-icon>
                        </button>
                        <button mat-fab
                                color="primary"
                                *ngIf="leftSideDisplayState === 'none'"
                                [ngClass]="{'fade-in': showFabButtons, 'fade-out': !showFabButtons}"
                                [matTooltip]="'DATA_SET.CODIFICATION_ELEMENT.DIAGNOSTIC.ADD_DIAGNOSTIC' | translate"
                                (mouseover)="changeFabButtonOver(true)"
                                (mouseout)="changeFabButtonOver(false)"
                                (click)="addDiagnostic()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>

                <ng-container>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="right-side-codification-history"
                         [@displayProperty]="commentDisplayState">
                        <ct-stay-detail-comment
                            [dataSetElement]="dataSetElement"
                        ></ct-stay-detail-comment>
                    </div>
                </ng-container>
                <ng-container *ctFeatureToggle="'codificationHistory'">
                    <mat-divider [vertical]="true"></mat-divider>

                    <div class="right-side-codification-history"
                         [@displayProperty]="codificationHistoryDisplayState">
                        <ct-stay-detail-codification-history
                            [dataSetElement]="dataSetElement"></ct-stay-detail-codification-history>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <!-- No data -->
        <div *ngIf="notFound">
            <mat-card class="no-data-card">
                <div class="row text-center">
                    <div class="col">
                    <span class="font-size-20 color-dark-grey">
                    {{'ERROR.UNKNOWN_STAY' | translate}}
                </span>
                    </div>
                </div>
            </mat-card>
        </div>
    </ng-container>

    <div
        class="align-center-center full-height"
        *ngIf="$state.params.isDialog === 'true' && !isActive"
    >
        <mat-progress-spinner
            mode="indeterminate"
            [diameter]="220"
        ></mat-progress-spinner>
    </div>
</div>
