import {Component, OnInit} from '@angular/core';
import {DiagnosticService} from '../../../../../diagnostic/diagnostic.service';
import {TranslationHelperService} from '../../../../../../core/services/translation.helper.service';
import {DataSetElementService} from '../../../../shared/data-set-element.service';
import {BroadcastService} from '../../../../../../core/services/broadcast.service';
import {LayoutService} from '../../../../../layout/layout.service';
import {TranslateService} from '@ngx-translate/core';
import {DataSetElement} from '../../../../shared/data-set-element.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {StayDetailEditActExecutionDateDialogComponent} from '../../../codification/act/edit-act-date-dialog/stay-detail-edit-act-execution-date-dialog.component';
import {SnackBarService} from '../../../../../../core/services/snack-bar.service';

@Component({
    selector: 'ct-stay-detail-act-prediction-element-tooltip',
    templateUrl: './stay-detail-act-prediction-element-tooltip.component.html',
    styleUrls: ['./stay-detail-act-prediction-element-tooltip.component.scss',
        '../../../diagnostic-prediction/element/tooltip/stay-detail-diagnostic-prediction-element-tooltip.component.scss']
})
export class StayDetailActPredictionElementTooltipComponent implements OnInit {
    codificationLabels = [];
    predictiveAct: any;
    dataSetElement: DataSetElement;

    constructor(
        private _matDialog: MatDialog,
        private _dataSetElementService: DataSetElementService,
        private _diagnosticService: DiagnosticService,
        private _translationHelperService: TranslationHelperService,
        private _broadcastService: BroadcastService,
        private _layoutService: LayoutService,
        private _translateService: TranslateService,
        private _snackBarService: SnackBarService,
    ) {
    }

    ngOnInit(): void {
        this.dataSetElement = this._dataSetElementService.dataSetElement;
        this._setCodificationLabel();
    }

    _setCodificationLabel() {
        const typeApp = this._dataSetElementService.isType(this.dataSetElement, 'rehabilitation') ? 'SSR' : 'MCO';
        const acts = this._translationHelperService.getCodificationActLabelsByAppType(typeApp);
        this.codificationLabels = Object.keys(this._diagnosticService.labels).filter(label => acts.includes(label));
    }

    async updateCodification(label: any): Promise<void> {
        if (this.predictiveAct
            && label) {
            const data = {
                actIds: [this.predictiveAct.id],
                type: label.slug,
            };
            this._layoutService.startLoading();
            const resp = await this._dataSetElementService.addActs(this.dataSetElement.id, data);
            this._broadcastService.send('act::addAct', resp);
            this._layoutService.stopLoading();
        }
    }
        openExecuteDateDisplayDialog(linkAct: any) {
        const dialogRef: MatDialogRef<StayDetailEditActExecutionDateDialogComponent> =
            this._matDialog.open(StayDetailEditActExecutionDateDialogComponent, {
                data: {
                    linkAct
                },
                autoFocus: false,
                panelClass: 'data-set-element-dates-edit-dialog'
            });

        dialogRef
            .afterClosed()
            .subscribe(async res => {
                if (res) {
                    this.addAct(res.new);
                }
            });
    }

    async addAct(act: any) {
        try {
            act.isLoading = true;
            const addedActs = await this._dataSetElementService
                .addActs(this.dataSetElement.id, {data: [{actId: act.id, execution_date: act.execution_date}], type: act.type});
            if (addedActs) {
                this._snackBarService.success(this._translateService.instant('SUCCESS.ADD_ACT'));
                this._broadcastService.send('act::addActs', {acts: [addedActs[0]]});
            }
            act.isLoading = false;
        } catch (e) {
            console.error(e);
        }
    }

    getMatTooltip(label: any): string {
        if (label) {
            return `${this._translateService
                .instant('DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_ACT.ADD_ACT')} ${label}`;
        }
        return '';
    }

    showJustification(): void {
        if (this.predictiveAct?.act) {
            let stay: any;
            if (this.dataSetElement) {
                stay = this.dataSetElement.dataSetContent;
            }
                this._broadcastService.send('dataSetElementStayJustification::addTab', {
                    diagnostic: this.predictiveAct.act,
                    stay,
                    isAct: true
                });
        }
    }
}
