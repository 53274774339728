export enum CodificationLabelEnum {
    DP = 'DP',
    DR = 'DR',
    DA = 'DA',
    DP_ROOT = 'DP_ROOT',
    DA_ROOT = 'DA_ROOT',
    HEALTH_STAY_SEVERITY = 'HEALTH_STAY_SEVERITY',
    FP = 'FP',
    MP = 'MP',
    AE = 'AE',
    CCAM = 'CCAM',
    CSARR = 'CSARR',
}

export type CodificationLabel = CodificationLabelEnum.DP
    | CodificationLabelEnum.DR
    | CodificationLabelEnum.DA
    | CodificationLabelEnum.DP_ROOT
    | CodificationLabelEnum.DA_ROOT
    | CodificationLabelEnum.HEALTH_STAY_SEVERITY
    | CodificationLabelEnum.FP
    | CodificationLabelEnum.MP
    | CodificationLabelEnum.AE
    | CodificationLabelEnum.CCAM
    | CodificationLabelEnum.CSARR;
