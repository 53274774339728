import {CodificationLabelEnum} from './codification-label-enum';

export interface CodificationLabels {
    [CodificationLabelEnum.DP]: number;
    [CodificationLabelEnum.DR]: number;
    [CodificationLabelEnum.DA]: number;
    [CodificationLabelEnum.DP_ROOT]: number;
    [CodificationLabelEnum.DA_ROOT]: number;
    [CodificationLabelEnum.HEALTH_STAY_SEVERITY]: number;
    [CodificationLabelEnum.FP]: number;
    [CodificationLabelEnum.MP]: number;
    [CodificationLabelEnum.AE]: number;
    [CodificationLabelEnum.CCAM]: number;
    [CodificationLabelEnum.CSARR]: number;
}

export class CodificationLabelsModel implements CodificationLabels {
    public DP: number;
    public DR: number;
    public DA: number;
    public DP_ROOT: number;
    public DA_ROOT: number;
    public HEALTH_STAY_SEVERITY: number;
    public FP: number;
    public MP: number;
    public AE: number;
    public CCAM: number;
    public CSARR: number;

    public getCodificationLabels() {
        return [
            this.DP,
            this.DR,
            this.DA,
            this.DP_ROOT,
            this.DA_ROOT,
            this.HEALTH_STAY_SEVERITY,
            this.FP,
            this.MP,
            this.AE,
            this.CCAM,
            this.CSARR,
        ];
    }

    public getAppTypeLabels(isRehabilitation: boolean): number[] {
        if (isRehabilitation === false) {
            return [
                this.DP,
                this.DR,
                this.DA,
                this.DP_ROOT,
                this.DA_ROOT,
                this.HEALTH_STAY_SEVERITY,
            ];
        } else {
            return [
                this.DA,
                this.DA_ROOT,
                this.FP,
                this.MP,
                this.AE
            ];
        }
    }
    public getAppTypeMainLabels(isRehabilitation: boolean): number[] {
        if (isRehabilitation === false) {
            return [
                this.DP,
                this.DR,
                this.DA,
            ];
        } else {
            return [
                this.DA,
                this.MP,
                this.AE
            ];
        }
    }

    public getSlugFromId(id: number) {
        return Object.keys(this).find(key => this[key] === id);
    }
}
